import React from 'react';
import './MtsSpinner.scss';

export default function MtsSpinner() {
  return (
    <div className="mts-spinner-align-center">
      <div className="mts-spinner-loader"></div>
    </div>
  );
}
