import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import getUserLocale from './getUserLocale';
import 'moment/locale/es';
import 'moment/locale/pt-br';
import moment from 'moment-timezone';
import translations from './translations';

const config = {
  lng: getUserLocale(),
  supportedLngs: ['en', 'pt-BR', 'es'],
  fallbackLng: 'pt-BR',
  debug: process.env.NODE_ENV === 'production' ? false : true,
  resources: translations,
};

if (!config.supportedLngs.includes(config.lng)) {
  config.lng = 'pt-BR';
}
localStorage.setItem('i18nextLng', config.lng);

i18n.use(initReactI18next).init({
  ...config,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
    format: (value, format, lng) => {
      if (value instanceof moment) {
        return value.locale(lng).format(format);
      }
      return value;
    },
  },
});

export default i18n;
