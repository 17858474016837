import React from 'react';
import Routes from './routes';
import addGMT from './gmt';

const App = () => {
  addGMT();

  return <Routes />;
};

export default App;
