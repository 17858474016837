import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { tryReadCampaignFromUrl } from 'utils/campaign-codes';
import App from 'utils/app';
import * as serviceWorker from './serviceWorker';

import 'sass/main.scss';
import './i18n/i18n';


import 'mtsAssets/scss/mentorise.scss';
import CookiesPopUp from 'components/cookies-pop-up';

tryReadCampaignFromUrl();

ReactDOM.render(
  <Suspense>
    <CookiesPopUp />
    <App />
  </Suspense>,
  document.getElementById('root')
);

serviceWorker.unregister();
