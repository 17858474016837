import { validateLoggedUser } from 'utils/validateLoggedUser';

const getUserLocale = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlLocale = urlParams.get('lng');

  if (urlLocale) {
    urlParams.delete('lng');
    let newParams = urlParams.toString();
    if (newParams) {
      newParams = '?' + newParams;
    }
    const newUrl = window.location.toString().split('?')[0] + newParams;
    window.history.replaceState('', '', newUrl);
    return urlLocale;
  }

  const localLocale = localStorage.getItem('i18nextLng');

  if (localLocale) {
    return localLocale;
  }

  const user = validateLoggedUser();

  if (user && user.locale) {
    return user.locale;
  }

  if (navigator.languages) {
    return navigator.languages[0];
  }

  if (navigator.language) {
    return navigator.language;
  }

  return 'en';
};

export default getUserLocale;
