import urls from 'config/urls';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

const CookiesPopUp = () => {
  const [showPopUp, setShowPopUp] = useState(
    !JSON.parse(localStorage.getItem('MseCookiesAccepted'))
  );
  const { t } = useTranslation(['cookiesPolicy']);

  const acceptCookies = () => {
    setShowPopUp(false);
    localStorage.setItem('MseCookiesAccepted', true);
  };

  if (showPopUp) {
    return (
      <div className="mts-cookies-pop-up p-2">
        <div className="d-flex inline-block d-flex align-items-center justify-content-evenly">
          <div className="text-white">
            <p className="font-bold new-line mb-2">{t('weUseCookies')}</p>
            <p className="new-line">{t('description')}</p>
          </div>
          <div className="d-flex flex-column">
            <button
              className="mts-btn mts-btn-primary mts-btn-sm"
              title={t('continueAndClose')}
              onClick={acceptCookies}
            >
              {t('continueAndClose')}
            </button>
            <a
              className="mts-btn mts-btn-sm"
              title={t('seeMore')}
              href={urls.COOKIES_POLICY.path}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('seeMore')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default CookiesPopUp;
