const BASE = process.env.REACT_APP_BASE_ROUTE;

const urls = {
  HOME: { path: `${BASE}`, name: '' },
  TEACHER: { path: `${BASE}mentor`, name: '' },
  MENTORS: { path: `${BASE}mentores`, name: '' },
  MENTOR: { path: `${BASE}u/:username`, name: '' },
  PAYMENT: {
    path: `${BASE}mentor/:mentorID/:typeMentoringSelected/pagamento`,
    name: '',
  },
  RATING: { path: `${BASE}avaliacao`, name: '' },
  MY_ACCOUNT: { path: `${BASE}minha-conta`, name: '' },
  LOGIN: { path: `${BASE}login`, name: '' },
  LOGIN_FROM_LINKEDIN: { path: `${BASE}login/:token/:id`, name: '' },
  FORGOT_PASSWORD: { path: `${BASE}esqueceu-senha`, name: '' },
  RESET_PASSWORD: { path: `${BASE}alterar-senha/:token/:email`, name: '' },
  REGISTER: { path: `${BASE}cadastro`, name: '' },
  TERMS: { path: `${BASE}termos`, name: '' },
  PRIVACY_POLICIES: { path: `${BASE}politica-privacidade`, name: '' },
  IN_APP_MAIL: { path: `${BASE}mail`, name: '' },
  MENTORING_WAITING: { path: `${BASE}mentoria/:hash`, name: '' },
  MENTORING_CALL: { path: `${BASE}mentoria/:hash/chamada`, name: '' },
  DASHBOARD_ADMIN_ORGANIZATION: {
    path: `${BASE}dashboard-admin/org/:id`,
    name: '',
  },
  DASHBOARD_ADMIN: { path: `${BASE}dashboard-admin`, name: '' },
  ARTICLES: { path: `https://blog.mentorise.com`, name: '' },
  RESCHEDULE: { path: `${BASE}reschedule/:id`, name: '' },
  PROPOSE_SCHEDULE: { path: `${BASE}propose-schedule/:id`, name: '' },
  CONFIRM_PROPOSED_SCHEDULE: { path: `${BASE}confirm-schedule/:id`, name: '' },
  CONFIRM_RESCHEDULE: { path: `${BASE}confirm-reschedule/:id`, name: '' },
  MEMBERS: { path: `${BASE}b2b-admin/members`, name: '' },
  HISTORIC: { path: `${BASE}b2b-admin/historic`, name: '' },
  SMART_MATCH: { path: `${BASE}b2b-admin/smart-match`, name: '' },
  B2B_DASHBOARD: { path: `${BASE}b2b-admin/dashboard`, name: '' },
  DEPARTMENTS: { path: `${BASE}b2b-admin/departments`, name: '' },
  CONFIRM_MENTORING: { path: `${BASE}confirm-mentoring/:id`, name: '' },
  CONFIRM_MENTORING_PACKAGE: {
    path: `${BASE}confirm-mentoring-package/:id`,
    name: '',
  },
  CANCEL_MENTORING_PACKAGE: {
    path: `${BASE}cancel-package/:id`,
    name: '',
  },
  FOR_ENTERPRISES: { path: `${BASE}para-empresas`, name: '' },
  MENTOR_FEEDBACK: { path: `${BASE}mentor-feedback`, name: '' },
  COOKIES_POLICY: { path: `${BASE}cookies-policy`, name: '' },
  MENTORISE_USER_DASHBOARD: { path: `${BASE}dashboard`, name: '' },
  SIMPLIFIED_REGISTRATION: { path: `${BASE}formulario-mentor`, name: '' },
  FEEDBACK: { path: `${BASE}dashboard?tab=ratings`, name: '' },
  DASHBOARD: { path: `${BASE}dashboard?tab=dashboard`, name: '' },
};

export default urls;
